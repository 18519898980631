<template>
  <app-module-view>
    <template slot="body">
      <div class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button v-if="showSaveAndAddNew()"
                      type="button"
                      class="btn btn-success"
                      @click="saveAndAddNew"
              >
                <i class="fa fa-save "></i> {{ $t('printArticle.saveAndAddNew') }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('printArticle.save') }}
              </button>
              <app-button-delete v-if="printArticle.id" @deleteRecord="deleteRecord"></app-button-delete>
              <app-button-close route-name="printArticle_list"></app-button-close>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <app-select
                    v-model="printTitleId"
                    :options="printTitles"
                    id="printTitle_title"
                    :label="$t('printPublication.title_title')">
                  </app-select>
                </div>
                <div class="col-lg-9" v-if="printTitleId">
                  <label>{{ $t('printArticle.publication') }}</label>
                  <app-multi-select
                    v-model="publication"
                    :options="publications"
                    label="title"
                    track-by="id"
                    :loading="isLoading"
                    :internal-search="false"
                    :options-limit="300"
                    :limit="10"
                    :max-height="600"
                    :multiple="false"
                    :close-on-select="true"
                    @search-change="findPublications"
                    id="printArticle_publication"
                  >
                  </app-multi-select>
                </div>
              </div>
              <div class="row" v-if="printTitleId">
                <div class="col-lg-8">
                  <label>
                    {{ $t('edonProduct.rubrics') }}
                  </label>
                  <app-multi-select
                    v-model="rubric"
                    :options="rubrics"
                    :loading="isLoading"
                    label="title"
                    track-by="id"
                    :multiple="false"
                    :close-on-select="true"
                    id="printArticle_rubric"
                  >
                  </app-multi-select>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <app-input
                    v-model="printArticle.title"
                    @blur="$v.printArticle.title.$touch()"
                    :error="$v.printArticle.title.$error"
                    id="printArticle_title"
                    :label="$t('printArticle.title') + ' *'"
                  >
                  </app-input>
                </div>
                <div class="col-lg-2">
                  <app-choice
                    name="printArticle.type"
                    v-model="printArticle.type"
                    :options="printArticleTypes"
                    :label="$t('printArticle.type')"
                  ></app-choice>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <label>
                    {{ $t('printArticle.sharedUsers') }}
                    <span class="required">*</span>
                  </label>
                  <app-multi-select
                    v-model="sharedUsers"
                    @blur="$v.sharedUsers.$touch()"
                    :error="$v.sharedUsers.$error"
                    :options="users"
                    label="username"
                    track-by="id"
                    id="printArticle_sharedUsers"
                  >
                  </app-multi-select>
                </div>
                <div class="col-lg-2">
                  <app-choice
                    name="printArticle.quality"
                    v-model="printArticle.quality"
                    :options="printArticleQualities"
                    :label="$t('printArticle.quality')"
                  ></app-choice>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <app-choice
                    name="printArticle.source"
                    v-model="printArticle.source"
                    :options="printArticleSources"
                    :label="$t('printArticle.source')"
                  ></app-choice>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 offset-6">
                  <label class="custom-control custom-checkbox pl-0" for="printArticle_native">
                    {{ $t('printArticle.native') }}
                  </label>
                  <app-checkbox v-model="printArticle.native" id="printArticle_native"></app-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import PrintArticleModel from '../../model/PrintArticle'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import Checkbox from '../form/Checkbox'
import Choice from '../form/Choice'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import NotifyService from '../../services/NotifyService'
import MultiSelect from '../form/select/MultiSelect'
import { PRINT_ARTICLE_TYPES } from '../../model/ValueObject/PrintArticleType'
import { PRINT_ARTICLE_QUALITIES } from '../../model/ValueObject/PrintArticleQuality'
import { PRINT_ARTICLE_SOURCES } from '../../model/ValueObject/PrintArticleSource'
import CoreApi from '../../api/core'
import { API_NAME as PUBLICATION_API } from '../../store/modules/printPublicationStore'
import { prettyDate } from '../../filters'

const DEFAULT_PUBLICATIONS_LIMIT = 10

export default {
  name: 'PrintArticleNew',
  data () {
    return {
      printArticle: this._.cloneDeep(PrintArticleModel),
      printArticleTypes: PRINT_ARTICLE_TYPES,
      printArticleQualities: PRINT_ARTICLE_QUALITIES,
      printArticleSources: PRINT_ARTICLE_SOURCES,
      sharedUsers: [],
      publications: [],
      publication: null,
      printTitleId: null,
      publicationId: null,
      isLoading: false,
      rubrics: [],
      rubric: null
    }
  },
  validations: {
    printArticle: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      }
    },
    sharedUsers: {
      required
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appChoice: Choice,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMultiSelect: MultiSelect
  },
  computed: {
    users () {
      return this.$store.getters['user/all']
    },
    printTitles () {
      return this.$store.getters['printTitle/all'].filter(
        printTitle => printTitle.site
      )
    }
  },
  watch: {
    async printTitleId () {
      if (this.printTitleId) {
        const site = this.$store.getters['printTitle/printTitleById'](this.printTitleId)
        await this.$store.dispatch('rubric/fetchRubricsBySiteId', site.site)
        this.rubrics = this.$store.state.rubric.all.filter(item => item.site === site.site)
        this.$store.dispatch('printPublication/fetchLatestBySite', {
          siteId: this.printTitleId,
          limit: DEFAULT_PUBLICATIONS_LIMIT
        }).then(() => {
          this.publications = this.$store.getters['printPublication/list'].map((publication) => {
            publication.title += ' ' + prettyDate(publication.publicationDate)
            return publication
          })
        })
      }
    },
    sharedUsers () {
      this.printArticle.sharedUsers = this.sharedUsers.map(function (user) {
        return user.id
      })
    },
    publication () {
      let publicationId = null
      if (this.publication) {
        publicationId = this.publication.id
      }
      this.printArticle.publication = publicationId
    },
    rubric () {
      let rubricId = null
      if (this.rubric) {
        rubricId = this.rubric.id
      }
      this.printArticle.rubric = rubricId
    }
  },
  methods: {
    findPublications (title) {
      if (!title) {
        return
      }
      this.isLoading = true
      CoreApi().get(PUBLICATION_API +
        '?filter_contains[title]=' + title +
        '&filter_eq[printTitle]=' + this.printTitleId
      )
        .then(response => {
          this.publications = response.data.data
          this.isLoading = false
        })
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('printArticle.error.required_fields'))
        return
      }

      this.$store.dispatch('printArticle/create', this.printArticle)
        .then(() => {
          if (this.$store.getters['printArticle/error'] === null) {
            this.printArticle = this.$store.getters['printArticle/detail']
            NotifyService.setSuccessMessage(this.$t('printArticle.message.updated_record'))
            this.$router.push('/printArticle/' + this.printArticle.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },
    async saveAndAddNew () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('printArticle.error.required_fields'))
        return
      }

      this.$store.dispatch('printArticle/create', this.printArticle)
        .then(() => {
          if (this.$store.getters['printArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('printArticle.message.updated_record'))
            this.printArticle.id = 0
            this.printArticle.sharedUsers = this.sharedUsers = []
            this.printArticle.title = this.printArticle.type = this.printArticle.quality = this.printArticle.source = ''
            this.printArticle.native = false
            this.printArticle.rubrics = this.rubrics = []
            this.$v.reset()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('printArticle/deleteRecord', this.printArticle)
        .then(() => {
          if (this.$store.getters['printArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('printArticle.message.deleted_record'))
            this.$router.push('/printArticle')
          } else {
            NotifyService.setErrorMessage(this.$t('printArticle.error.' + this.$store.getters['printArticle/error']))
          }
        })
        .catch(error => console.log(error))
    },
    showSaveAndAddNew () {
      return true
    }
  },
  beforeMount () {
    this.$store.dispatch('printTitle/fetchAll')
  }
}
</script>
